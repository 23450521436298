import classNames from 'classnames';
import { shape, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import DrawerButtonsSidebarItem from './items/drawerButtons';
import FullscreenButton from './items/fullscreenButton';
import NavigationSidebarItem from './items/navigation';
import SlidingButton from './items/slidingButton';
import ToolsSidebarItem from './items/tools';

import Drawer from './drawer';

import { getTeacherFeaturesEnabledFor } from '../../../../selectors/digibooks';
import { getIsRendered } from '../../../../selectors/player';
import ContentCounterContext, { ContentCounterProvider } from '../../context/ContentCounterContext';
import { MobileSizeContext } from '../../context/MobileSizeContext';
import { UserSettingsContext } from '../../context/user-settings-context';

const panelRoot = 'pbb-panel';
const SLIDING_BUTTON_HEIGHT = 50;

function calculateSidebarHeight(itemsCount, dividersCount) {
  const computedStyle = window.getComputedStyle(document.documentElement);

  const sidebarItemHeight = Number(computedStyle.getPropertyValue('--sidebar-item-height').replace('px', ''));
  const dividerHeight = 1;

  return itemsCount * sidebarItemHeight + dividersCount * dividerHeight + SLIDING_BUTTON_HEIGHT;
}

function useContentCounter() {
  const { increaseDividersCounterBy, increaseItemsCounterBy } = useContext(ContentCounterContext);

  useLayoutEffect(() => {
    increaseDividersCounterBy(2);
    increaseItemsCounterBy(3);
  }, [increaseDividersCounterBy, increaseItemsCounterBy]);
}

function Sidebar(props) {
  const { superModuleId, digibook } = props;
  const { sidebarAnchor, activeDrawer, isSidebarOpen, setShowMarkings } = useContext(UserSettingsContext);

  const { setMaxDesktopHeight } = useContext(MobileSizeContext);
  const { dividersCount, itemsCount } = useContext(ContentCounterContext);

  useContentCounter();

  const handleResize = useCallback(() => {
    setMaxDesktopHeight(calculateSidebarHeight(itemsCount, dividersCount));
  }, [dividersCount, itemsCount, setMaxDesktopHeight]);

  useLayoutEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const bookIsRendered = useSelector(getIsRendered);
  const teacherFeaturesEnabled = useSelector(getTeacherFeaturesEnabledFor);

  return (
    <div className={classNames(panelRoot, `${panelRoot}--${sidebarAnchor}`)} data-testid="sidebar">
      <div className="pbb-sidebar">
        {bookIsRendered && <SlidingButton superModuleId={superModuleId} slideOutDelay={500} />}
        <div>
          <div className="pbb-sidebar__divider" />
          <ToolsSidebarItem setShowMarkings={setShowMarkings} />
          <DrawerButtonsSidebarItem />
          <FullscreenButton />
          <NavigationSidebarItem />
          <div className="pbb-sidebar__divider" />
          <div className="pbb-sidebar__spacer" />
        </div>
      </div>
      <Drawer isOpen={isSidebarOpen} activeDrawer={activeDrawer} superModuleId={superModuleId} digibook={digibook} teacherFeaturesEnabled={teacherFeaturesEnabled} />
    </div>
  );
}

export default function ResponsiveSidebar(props) {
  return (
    <ContentCounterProvider test="sidebar">
      <Sidebar {...props} />
    </ContentCounterProvider>
  );
}

Sidebar.propTypes = {
  // Own Props
  superModuleId: string,
  digibook: shape({
    id: string.isRequired,
    module: string.isRequired,
  }).isRequired,
};
ResponsiveSidebar.propTypes = Sidebar.propTypes;
