import throttle from 'lodash/throttle';
import api from './api';

// eslint-disable-next-line no-console
function apiErrorSwallower(e) {
  console.error(e);
}

export default class UserMarkingsProviderService {
  constructor() {
    this.resetState();
  }

  resetState() {
    this.signedUrls = {};
    this.spreadsWithMarkings = undefined;
    this.uploaders = {};
  }

  async initialize(digibookId, systemToken, callback, activeAnnotationSetId) {
    this.digibookId = digibookId;
    this.config = { headers: { Authorization: `Bearer ${systemToken}` } };
    return this.getSpreadsWithMarkingsForAnnotationSet(activeAnnotationSetId, callback);
  }

  async getSpreadsWithMarkingsForAnnotationSet(annotationSetId, callback) {
    this.flushPendingUploads();
    this.resetState();
    this.activeAnnotationSetId = annotationSetId;

    const response = await api.get(`/studio/digibooks/${this.digibookId}/annotation-sets/${this.activeAnnotationSetId}/markings`, this.config);
    this.spreadsWithMarkings = new Set(response.data.data);

    if (callback) callback();
  }

  async getSignedUrlForSpread(spreadKey) {
    if (!this.signedUrls[spreadKey]) {
      await api
        .get(`/studio/digibooks/${this.digibookId}/annotation-sets/${this.activeAnnotationSetId}/markings/${spreadKey}/signed-url`, this.config)
        .then(response => {
          this.signedUrls = response.data;
        })
        .catch(apiErrorSwallower);
    }
    return this.signedUrls[spreadKey];
  }

  async getMarkingsForSpread(spreadKey) {
    if (this.spreadsWithMarkings && this.spreadsWithMarkings.has(spreadKey)) {
      const url = await this.getSignedUrlForSpread(spreadKey).catch(apiErrorSwallower);
      const response = await api.get(url, this.config).catch(apiErrorSwallower);
      return response.data;
    }
    return [];
  }

  async storeMarkingsForSpread(spreadKey, data) {
    const url = await this.getSignedUrlForSpread(spreadKey);
    if (!this.uploaders[spreadKey]) {
      this.uploaders[spreadKey] = throttle(uploadData => {
        api({
          url,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 'x-amz-acl': 'bucket-owner-full-control' },
          data: uploadData,
        })
          .then(() => {
            this.spreadsWithMarkings.add(spreadKey);
          })
          .catch(apiErrorSwallower);
      }, 3000);
    }

    this.uploaders[spreadKey](data);
  }

  async removeAllMarkingsForSpread(spreadKey) {
    const url = await this.getSignedUrlForSpread(spreadKey);
    return api
      .delete(url)
      .then(() => this.spreadsWithMarkings.delete(spreadKey))
      .catch(apiErrorSwallower);
  }

  flushPendingUploads() {
    if (this.uploaders) {
      Object.keys(this.uploaders).map(ul => this.uploaders[ul].flush());
    }
  }
}
