import { TextAnnotations } from '@pelckmans/business-components/components/text-annotations';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { filterShapesForVisiblePages } from '../modules/player/components/book/text-annotation/hooks/use-annotations';
import FabricService from '../modules/player/services/fabric-service';
import { getCurrentDigibook } from '../selectors/digibooks';
import { calculateSpreadForPageNumbers } from '../utils/calculateSpreadForPageNumbers';

// not needed for print
const translations = {};
const placeHolderHandler = () => {};
const customColors = [];

/**
 * Creates the elements needed to print a single page.
 * Calls back to parent when rendering is done.
 */
export default function PrintPage({ pageNumber, imageSrc, markings = [], annotations = [], onRenderDone }) {
  const canvasRef = useRef();
  const container = useRef();
  const bookPageImage = useRef();
  const digibook = useSelector(getCurrentDigibook);
  const [scale, setScale] = useState(1);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const isStandalonePage = pageNumber === 0 || pageNumber === 1 || pageNumber === digibook.totalPages + 1;
  const isRightPage = !isStandalonePage && calculateSpreadForPageNumbers([pageNumber])[1] === pageNumber;

  const onImageLoad = () => {
    canvasRef.current.style.width = `${container.current.clientWidth}px`;
    canvasRef.current.style.height = `${container.current.clientHeight}px`;
    setScale(container.current.clientHeight / bookPageImage.current.naturalHeight);
    setDimensions({
      width: bookPageImage.current.naturalWidth,
      height: bookPageImage.current.naturalHeight,
    });

    const fabricService = new FabricService(canvasRef.current);

    fabricService.setCanvasDimensions(container.current.clientHeight, container.current.clientWidth);
    fabricService.setPageDimensions(bookPageImage.current.naturalHeight, bookPageImage.current.naturalWidth);
    fabricService.fabricCanvas.setZoom(container.current.clientHeight / bookPageImage.current.naturalHeight);

    // Wait for next animation frame to ensure renderings are shown on canvas.
    fabricService.showMarkings(markings, true, isRightPage).then(() => {
      window.requestAnimationFrame(() => onRenderDone());
    });
  };

  return (
    <div className="page" ref={container}>
      <div className="subpage">
        <img src={imageSrc} alt={imageSrc} ref={bookPageImage} onLoad={onImageLoad} />
        <div className="canvas-wrapper">
          <canvas ref={canvasRef} />
        </div>
        <TextAnnotations
          annotations={filterShapesForVisiblePages(
            annotations,
            calculateSpreadForPageNumbers([pageNumber]).map(x => (x === pageNumber ? x : null)),
            dimensions.width,
          )}
          addAnnotation={placeHolderHandler}
          editAnnotation={placeHolderHandler}
          selectedAnnotation={null}
          setSelectedAnnotationId={placeHolderHandler}
          removeAnnotation={placeHolderHandler}
          viewportTransform={[scale, 0, 0, scale, 0, 0]}
          pageHeight={dimensions.height}
          pageWidth={dimensions.width}
          isSinglePage
          isRightPage={isRightPage}
          isStandalonePage={isStandalonePage}
          isSolutionsPageVisible={false}
          customColors={customColors}
          saveCustomColor={placeHolderHandler}
          sidebarAnchor="right"
          lang="nl"
          isInteractive={false}
          translations={translations}
        />
      </div>
    </div>
  );
}

PrintPage.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  imageSrc: PropTypes.string.isRequired,
  markings: PropTypes.arrayOf(PropTypes.object),
  annotations: PropTypes.arrayOf(PropTypes.object),
  onRenderDone: PropTypes.func.isRequired,
};
