import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import api from '../services/api';
import PrintPage from './print-page';

import notosansMono from '../../assets/fonts/noto-sans-mono.ttf';
import playwrite from '../../assets/fonts/playwrite.ttf';
import openSans from '../../assets/fonts/open-sans.ttf';
import printStyles from '../../scss/print/print-pages.scss?raw';

function keyBySpreadPages(arr) {
  return arr.reduce((acc, curr) => {
    const pages = curr.spread.split('-').map(Number);

    pages.forEach(page => {
      acc[page] = curr.data;
    });

    return acc;
  }, {});
}

async function createImageMap({ digibook, from, to, opts, includeAnswers }) {
  const backCoverPage = digibook.totalPages + 1;
  const fromPage = from === 0 ? 1 : from;
  const toPage = to === backCoverPage ? digibook.totalPages : to;
  const pageRange = to ? `${fromPage}-${toPage}` : fromPage;

  const includesCover = from === 0;
  const includesBackCover = to === backCoverPage;
  const onlyBackCoverRequested = from === backCoverPage;
  const onlyCoverRequested = includesCover && !to;

  const routes = [];
  if (!onlyCoverRequested && !onlyBackCoverRequested) {
    routes.push(`/studio/digibooks/${digibook.id}/book/print-pages/${pageRange}${includeAnswers ? '?includeAnswers=true' : ''}`);
  }

  if (includesCover || onlyCoverRequested) routes.unshift(`/studio/digibooks/${digibook.id}/cover/print-pages/1`);
  if (includesBackCover || onlyBackCoverRequested) routes.push(`/studio/digibooks/${digibook.id}/backcover/print-pages/1`);

  return (
    await Promise.all(
      routes.map(async route => {
        const response = await api.get(route, opts);

        if (route.includes('backcover')) {
          return {
            [backCoverPage]: response.data[1],
          };
        }

        if (route.includes('cover')) {
          return {
            0: response.data[1],
          };
        }

        return response.data;
      }),
    )
  ).reduce((acc, curr) => ({ ...acc, ...curr }));
}

async function getMarkingsByPage({ digibook, annotationSetId, opts, pageRange }) {
  const hasMarkings = (await api.get(`/studio/digibooks/${digibook.id}/annotation-sets/${annotationSetId}/markings`, opts)).data.data;

  const getMarkingSignedUrlsRoute = `/studio/digibooks/${digibook.id}/annotation-sets/${annotationSetId}/markings/${pageRange}/signed-url`;

  const { data: markingSets } = await api.get(getMarkingSignedUrlsRoute, opts);

  return keyBySpreadPages(
    await Promise.all(
      Object.keys(markingSets).map(async key => {
        if (hasMarkings.includes(key)) {
          try {
            const data = await api.get(markingSets[key], opts);
            return { ...data, spread: key };
          } catch (error) {
            return { data: [], spread: key };
          }
        } else {
          return { data: [], spread: key };
        }
      }),
    ),
  );
}

async function getTextAnnotationsByPage({ digibook, annotationSetId, opts, pageRange }) {
  const hasTextAnnotations = (await api.get(`/studio/digibooks/${digibook.id}/annotation-sets/${annotationSetId}/text-annotations`, opts)).data.data;
  const getTextAnnotationSignedUrlsRoute = `/studio/digibooks/${digibook.id}/annotation-sets/${annotationSetId}/text-annotations/${pageRange}/signed-url`;

  const { data: textAnnotations } = await api.get(getTextAnnotationSignedUrlsRoute, opts);

  return keyBySpreadPages(
    await Promise.all(
      Object.keys(textAnnotations).map(async key => {
        if (hasTextAnnotations.includes(key)) {
          try {
            const { data } = await api.get(textAnnotations[key], opts);
            return { data, spread: key };
          } catch (error) {
            return { data: [], spread: key };
          }
        } else {
          return { data: [], spread: key };
        }
      }),
    ),
  );
}

function PrintPages({ digibook, from, to, includeAnswers, onAfterPrint, annotationSetId, includeAnnotations }) {
  const iframeRef = useRef(null);
  const isSafari = window.safari !== undefined || /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const [printData, setPrintData] = useState();
  const opts = useMemo(() => ({ headers: { Authorization: `Bearer ${digibook.systemToken}` } }), [digibook.systemToken]);
  const initialPages = useMemo(() => new Array((to || from) - from + 1).fill(undefined).map((_, i) => from + i), [from, to]);
  const [rendered, setRendered] = useState(
    initialPages.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: false,
      }),
      {},
    ),
  );

  useEffect(() => {
    async function fetchPrintData() {
      const imagePerPageMap = await createImageMap({
        digibook,
        from,
        to,
        opts,
        includeAnswers,
      });

      if (!includeAnnotations) {
        setPrintData({
          imageUrls: imagePerPageMap,
        });

        return;
      }

      const pageRange = to ? `${from}-${to}` : from;

      const [markings, textAnnotations] = await Promise.all([
        getMarkingsByPage({ annotationSetId, digibook, opts, pageRange }),
        getTextAnnotationsByPage({ annotationSetId, digibook, opts, pageRange }),
      ]);

      setPrintData({
        imageUrls: imagePerPageMap,
        markings,
        textAnnotations,
      });
    }

    fetchPrintData();
  }, [digibook, from, to, includeAnswers, opts, annotationSetId, includeAnnotations]);

  useEffect(() => {
    if (Object.values(rendered).every(x => x)) {
      const handleWindowFocus = () => {
        onAfterPrint();
        window.removeEventListener(isSafari ? 'afterprint' : 'focus', handleWindowFocus);
      };
      window.addEventListener(isSafari ? 'afterprint' : 'focus', handleWindowFocus);
      iframeRef.current.contentWindow.focus();
      iframeRef.current.contentWindow.print();
    }
  }, [rendered, onAfterPrint, isSafari]);

  return createPortal(
    <iframe style={{ visibility: 'hidden', width: '1px', height: '1px' }} data-testid="iframe" ref={iframeRef} title="print-pages">
      {printData &&
        createPortal(
          <>
            <style>
              {`
                  @font-face {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-stretch: 100%;
                    font-display: swap;
                    src: url(${openSans}) format('truetype');
                  }
                  @font-face {
                    font-family: 'NotoSansMono';
                    font-style: normal;
                    font-weight: 400;
                    font-stretch: 100%;
                    font-display: swap;
                    src: url(${notosansMono}) format('truetype');
                  }
                  @font-face {
                    font-family: 'Playwrite';
                    font-style: normal;
                    font-weight: 400;
                    font-stretch: 100%;
                    font-display: swap;
                    src: url(${playwrite}) format('truetype');
                  }
                  `}
            </style>
            <style>
              {`
                .page {
                    max-height: ${isSafari ? '290mm' : '296mm'};
                }
                .subpage {
                    max-height: ${isSafari ? '290mm' : '296mm'};
                }
                @media print {
                  html, body {
                  height: ${isSafari ? '294mm' : '296mm'};
                }
                `}
            </style>
            <style>{printStyles}</style>
            {Object.entries(printData.imageUrls).map(([page, url]) => (
              <PrintPage
                key={page}
                onRenderDone={() => {
                  setRendered(prev => ({
                    ...prev,
                    [page]: true,
                  }));
                }}
                imageSrc={url}
                pageNumber={Number(page)}
                markings={printData.markings?.[page]}
                annotations={printData.textAnnotations?.[page]}
              />
            ))}
          </>,
          iframeRef.current.contentWindow.document.body,
        )}
    </iframe>,
    document.body,
  );
}

PrintPages.propTypes = {
  digibook: PropTypes.shape({
    id: PropTypes.string.isRequired,
    systemToken: PropTypes.string.isRequired,
    totalPages: PropTypes.number.isRequired,
  }).isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number,
  includeAnswers: PropTypes.bool,
  onAfterPrint: PropTypes.func.isRequired,
  annotationSetId: PropTypes.number.isRequired,
  includeAnnotations: PropTypes.bool.isRequired,
};

export default PrintPages;
